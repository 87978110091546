<template>
  <div class="helmet-detail-content">

    <div class="gallery-container">
      <div class="helmet-display">
        <img class="helmet-image" :alt="helmetDetails.helmetNumber" :src="helmetDetails.imageFile">
      </div>

      <div class="actions-container">
        <div class="action-icons"  v-if="selectedHelmetStatus === 1">
          <div class="action-icon love-icon icon-white" @click="loveHelmet" :class="{ active: helmetDetails.isLoved }">
            <i class="fas fa-heart"></i>
          </div>
          <div class="action-icon shelf-icon icon-white" @click="shelveHelmet" :class="{ active: helmetDetails.isShelved }">
            <i class="fas fa-bookmark"></i>
          </div>
        </div>

        <div class="action-buttons" v-if="selectedHelmetStatus !== 1">
          <button class="nav-button prev-button" @click="getHelmet(0)">
            <i class="fas fa-chevron-left"></i>
            Previous
          </button>
          <button class="nav-button next-button" @click="getHelmet(1)">
            Next
            <i class="fas fa-chevron-right"></i>
          </button>
        </div>
      </div>
      
		<div class="current-view-text">
			<div class="current-view-text" v-if="selectedHelmetStatus === 1"> <b> Rate this Helmet</b> </div>
			<div class="current-view-text" v-if="selectedHelmetStatus === 2"> <b> Your Favorite <i class="fas fa-heart love-icon""></i> Helmets</b> </div>
			<div class="current-view-text" v-if="selectedHelmetStatus === 3"> <b> Your Shelved <i class="fas fa-bookmark shelf-icon"></i> Helmets</b> </div>
		</div>

		<div class="filter-container" >
			<div class="nav-text" @click="setSelectedHelmetStatus(1)" v-if="selectedHelmetStatus === 2"><b>Rate More</b> </div>
			<div class="nav-text love-icon" @click="setSelectedHelmetStatus(2)" v-if="selectedHelmetStatus !== 2">  <b> View Favorite <i class="fas fa-heart"></i> Helmets</b> </div>
			<div class="nav-text shelf-icon" @click="setSelectedHelmetStatus(3)" v-if="selectedHelmetStatus !== 3"> <b> View Shelved <i class="fas fa-bookmark"></i> Helmets</b> </div>
			<div class="nav-text" @click="setSelectedHelmetStatus(1)" v-if="selectedHelmetStatus === 3"><b>Rate More</b> </div>
		</div>


     </div>
    	
      <router-link to="/my-helmets">
    	<section :class="['section-1', { 'section-1--mobile': false }]">
    		<div class="title">
    			<span data-desktop-color="rgba(0,97,249,1)" style="color: rgb(0, 97, 249);"></span>
			</div>
			<div class="subtitle">
				<span data-desktop-color="rgba(0,97,249,1)" style="color: rgb(0, 97, 249);"><-- Back to Your Gallery</span>
			</div>
		</section>
      </router-link>

  </div>
</template>

<script>

import HelmetService from "../services/HelmetService";

export default {
  props: {
    helmetId: {
      type: String,
      required: true
    }
  },
  components: {
  },
  data() {
    return {
      helmetDetails: null,
      selectedHelmetStatus: 1,
      imgSrc: '',      
    };
  },
  methods: {
   
    loveHelmet() {
      this.helmetDetails.isLoved = !this.helmetDetails.isLoved;
      this.rateHelmet(this.helmetDetails.helmetId, 2);
    },
    
    shelveHelmet() {
      this.helmetDetails.isShelved = !this.helmetDetails.isShelved;
      this.rateHelmet(this.helmetDetails.helmetId, 3);
    },

    async rateHelmet(helmetId, helmetStatus) {

		const rateHelmetData = {
			helmetId : helmetId,
			helmetStatus : helmetStatus  
    	};
		
		try {
			let response = await HelmetService.rateHelmet(rateHelmetData);

			const findHelmetIdData = {
				helmetId : helmetId,
				helmetStatus : this.selectedHelmetStatus,  
				nextOrPrevious : 1
	    	};
	
			this.findHelmetIdAndGetHelmetDetails(findHelmetIdData);
			
		} catch (error) {
	        console.error('Error fetching helmet details:', error);
		}
    },
    
    setSelectedHelmetStatus(selectedHelmetStatus) {
    	this.selectedHelmetStatus = selectedHelmetStatus
    	this.getHelmetUsingNewlySelectedHelmetStatus();
    },
    
	async getHelmetUsingNewlySelectedHelmetStatus() {

		const findHelmetIdData = {
			helmetId : 16060,
			helmetStatus : this.selectedHelmetStatus,  
			nextOrPrevious : 1
    	};

		this.findHelmetIdAndGetHelmetDetails(findHelmetIdData);
	},

	async getHelmet(nextOrPreviousValue) {

		const findHelmetIdData = {
			helmetId : this.helmetId,
			helmetStatus : this.selectedHelmetStatus,  
			nextOrPrevious : nextOrPreviousValue
    	};

		this.findHelmetIdAndGetHelmetDetails(findHelmetIdData);
	},

	async findHelmetIdAndGetHelmetDetails(findHelmetIdData) {
	
		console.log(findHelmetIdData);

        const jsonData = JSON.stringify(findHelmetIdData);
        console.log(jsonData);

      	try {
			let response = await HelmetService.findHelmetId(jsonData);
			this.helmetId = response.data;
	
			this.getHelmetDetails();

	      } catch (error) {
	        console.error('Error fetching helmet details:', error);
	      }
	},

    async getHelmetDetails() {
      try {
			let response = await HelmetService.get(this.helmetId);
			this.helmetDetails = response.data;

  			location.hash = '#helmet/' + this.helmetId;

			this.selectedHelmetStatus = 
			    this.helmetDetails && 'helmetStatus' in this.helmetDetails && this.helmetDetails.helmetStatus !== null 
			    ? this.helmetDetails.helmetStatus 
			    : 1;

 			// fetching image
      		const imagePath = this.helmetDetails.imageFile;
      		const parts = imagePath.split("/");
      		const imageName = parts[parts.length - 1];
      		this.imgSrc = '/images/'+imageName;

			if (hasAnswers) {			
		        this.answers.forEach(function(answer) {
		            if (answer.answer === "Yes") {
		               temp = true;
		            } else {
		               temp = false;
		            }
		        });
			}


	    
      } catch (error) {
        console.error('Error fetching helmet details:', error);
      }
    },
  },
  mounted() {
    this.getHelmetDetails();
  },
  computed: {
  },
};
</script>

<style>

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css');

.ant-layout-header {
	display: flex !important;
}

.helmet-detail-content {
  max-width: 1200px;
  margin: 20px auto;
  padding: 0 20px;
  margin-top: -50px !important;
}

.gallery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-container {
  width: 100%;
  max-width: 400px;
  margin: 20px 0;
}

.filter-select {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 2px solid #1890FF;
  font-size: 1rem;
  background: white;
  color: #1890FF;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 400px !important;
}

.helmet-display {
  position: relative;
  width: 100%;
/*  max-width: 600px; */
/*  margin: 20px 0; */
}

.helmet-image {
  width: 100%;
  height: auto;
  border-radius: 20px;
  box-shadow: 0 4px 15px rgba(0,0,0,0.1);
}

.actions-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  min-height: 60px;
  
/*  margin-top: 20px;  */
}

.action-icons {
  display: flex;
  gap: 15px;
}

.action-icon {
  background: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.action-buttons {
  display: flex;
  gap: 20px;
}

.current-view-text {
	font-size: 24px;
	argin-top: 4px;
}

.nav-text {
 	margin: 5px;
 	padding: 5px;
	width: 180px;
	outline: 2px dashed blue;
	gap: 5px;
	font-style: italic;
}

.nav-button {
  padding: 12px 25px;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1rem;
}

.prev-button {
  background: linear-gradient(45deg, #4a90e2, #1890FF);
  color: white;
}

.next-button {
  background: linear-gradient(45deg, #1890FF, #4a90e2);
  color: white;
}

.love-icon {
  color: #ff4757;
}

.action-icons .love-icon:hover {
  background: #ffcccc;
  color: #ff4757;
  transform: scale(1.1);
}

.shelf-icon {
  color: #747d8c;
}

.action-icons .shelf-icon:hover {
  background: lightGrey;
  transform: scale(1.1);
}

.icon-white {
	color: Grey;
}

.active {
  transform: scale(1.1);
  background: #f1f2f6;
}

@media (max-width: 600px) {
  .actions-container {
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 200px) {
  .action-buttons {
    flex-direction: column;
    width: 100%;
  }
  
  .nav-button {
    width: 100%;
    justify-content: center;
  }
}

.section-1 {
  line-height: 34px;
  background-color: white;
  color: rgb(0, 97, 249);
  text-align: center;
  font-weight: 500;
  opacity: 1;
  font-size: 42px;
  font-style: italic;
}

.section-1.section-1--mobile {
  line-height: 24px;
  font-size: 28px;
}

.section-1 .subtitle {
  background-color: white;
  color: rgb(0, 97, 249);
  text-align: center;
  font-weight: 400;
  opacity: 1;
  font-size: 21px;
  font-style: italic;
}

.section-1.section-1--mobile .subtitle {
  font-size: 14px;
}

.current-view-text {
	color: #444;
}

</style>

