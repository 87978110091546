
<template>
  <div class="content">
    <!-- search bar -->

    <div class="row my-3 justify-content-end">
      <div class="col-8"></div>
       <div class="col-auto">
        <!-- Header Search Input -->
        
        <a-input-search class="header-search" :class="searchLoading ? 'loading' : ''" placeholder="Search by color, sponsor, or style...."
          @search="onSearch" :loading='searchLoading' v-model="searchQuery">
          <svg slot="prefix" width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 9.29583 13.5892 10.4957 12.8907 11.4765L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L11.4765 12.8907C10.4957 13.5892 9.29583 14 8 14C4.68629 14 2 11.3137 2 8Z"
              fill="#111827" />
          </svg>
        </a-input-search>
        
		<div class="filter-chip-container">
	
			<button :class="['filter-chip', { 'filter-chip-selected': selectedHelmetStatus===2 }]" @click="setSelectedHelmetStatus(2)"> <b> Favorites </b></button>
			<button :class="['filter-chip', { 'filter-chip-selected': selectedHelmetStatus===3 }]" @click="setSelectedHelmetStatus(3)"> <b> Shelved   </b></button>
			<button :class="['filter-chip', { 'filter-chip-selected': selectedHelmetStatus===1 }]" @click="setSelectedHelmetStatus(1)"> <b> Unrated   </b></button>
		</div>
        
		<div class="current-view-text">
			<div v-if="selectedHelmetStatus === 1">  <b> Your Unrated Helmets </b> </div>
			<div v-if="selectedHelmetStatus === 2">  <b> Your Favorite <i class="fas fa-heart love-icon"></i> Helmets</b> </div>
			<div v-if="selectedHelmetStatus === 3">  <b> Your Shelved <i class="fas fa-bookmark shelf-icon"></i> Helmets</b> </div>
		</div>

        <!-- / Header Search Input -->
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card>
          <template slot="header">
            <div class="row justify-content-between align-items-between mx-3">

              <h5 class="card-title">Helmets</h5>
              
              <div>
                  <base-button class="btn btn-primary" @click="modalHelmets = true">Add</base-button>
	              <base-button class="btn btn-primary" @click="toggleView">Toggle View</base-button>
              </div>
              
              <modal :show.sync="modalHelmets">
                <template slot="header">
                  <h5 class="modal-title" id="exampleModalLabel">Add Helmet</h5>
                </template>
                <div>
                  <form @submit.prevent>
  <base-input label="HelmetId" type="text" placeholder="Enter HelmetId" v-model="helmetToAdd.helmetId"></base-input>
                  </form>
                </div>
                <template slot="footer">
                  <base-button type="primary" @click="handleAddSubmitted()">Save</base-button>
                </template>
              </modal>
            </div>
          </template>


          <!-- Conditionally render the view based on the 'isTableView' flag -->
          <div v-if="isTableView">
            <!-- Render the existing Table View -->
            <a-table :columns="columns" :data-source="helmets" :row-key="record => record.HelmetId" :pagination="pagination"
              :loading="searchLoading" @change="onTableChange" :scroll="{ x: 'max-content' }">

              <template slot="helmetId" slot-scope="dataIndex">
              	<router-link  :to="`/helmet/${dataIndex}`"><a >{{ dataIndex }}</a></router-link>
              </template>


             <template slot="lastModified" slot-scope="dataIndex">
              	{{ formatTime(dataIndex) }}
              </template>
              <template slot="createdOn" slot-scope="dataIndex">
              	{{ formatTime(dataIndex) }}
              </template>
              <template slot="blackOutStartDate" slot-scope="dataIndex">
              	{{ formatDate(dataIndex) }}
              </template>
            </a-table>
          </div>
          <div v-else>
            <!-- Render the Picture View  -->
            <HelmetPictureView :helmets="helmets" />
          </div>

        </card>
      </div>
    </div>

  </div>
</template>

<script>
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/BaseInput";
import NotificationTemplate from "@/pages/Notifications/NotificationTemplate";
import { Card } from "@/components/Card";
import HelmetService from "../services/HelmetService";
import { ASelect, ASelectOption, AButton, Table, Pagination } from "ant-design-vue";
import HelmetPictureView from './HelmetPictureView.vue';


const helmetsColumns = [
  "helmetId",
  "year",
  "date",
  "competitionId",
  "helmetId"
]

export default {
  props: {
    helmets: {
      type: Array,
      required: true,
    },
    totalElements: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
  },
  components: {
    Card,
    Modal,
    BaseButton,
    BaseInput,
    Table,

    Pagination,
    HelmetPictureView
  },

  data() {
    return {
      modalHelmets: false,
        isTableView: false,
      selectedHelmetStatus: 2,

      columns: [
        {
          title: 'Helmet ID',
				dataIndex: 'helmetId',
	          visible: false,
          scopedSlots: { customRender: 'helmetId' },
          sorter: true
          //sorter: (a, b) => a.helmetId - b.helmetId,
          //sorter: (a, b) => a.helmetId.localeCompare(b.helmetId),
        },
        {
          title: 'DriverName',
		dataIndex: 'driverName',
          visible: true,
          scopedSlots: { customRender: 'driverName' },
          sorter: true
          //sorter: (a, b) => a.driverName - b.driverName,
          //sorter: (a, b) => a.driverName.localeCompare(b.driverName),
        },
        {
          title: 'Series',
		dataIndex: 'series',
          visible: true,
          scopedSlots: { customRender: 'series' },
          sorter: true
          //sorter: (a, b) => a.series - b.series,
          //sorter: (a, b) => a.series.localeCompare(b.series),
        },
        {
          title: 'HelmetBrand',
		dataIndex: 'helmetBrand',
          visible: true,
          scopedSlots: { customRender: 'helmetBrand' },
          sorter: true
          //sorter: (a, b) => a.helmetBrand - b.helmetBrand,
          //sorter: (a, b) => a.helmetBrand.localeCompare(b.helmetBrand),
        },
        {
          title: 'helmetModel',
		dataIndex: 'helmetModel',
          visible: true,
          scopedSlots: { customRender: 'helmetModel' },
          sorter: true
          //sorter: (a, b) => a.helmetModel - b.helmetModel,
          //sorter: (a, b) => a.helmetModel.localeCompare(b.helmetModel),
        },
        {
          title: 'HelmetName',
		dataIndex: 'helmetName',
          visible: true,
          scopedSlots: { customRender: 'helmetName' },
          sorter: true
          //sorter: (a, b) => a.helmetName - b.helmetName,
          //sorter: (a, b) => a.helmetName.localeCompare(b.helmetName),
        },
        {
          title: 'active',
		dataIndex: 'active',
          visible: true,
          scopedSlots: { customRender: 'active' },
          sorter: true
          //sorter: (a, b) => a.active - b.active,
          //sorter: (a, b) => a.active.localeCompare(b.active),
        },
        {
          title: 'description',
		dataIndex: 'description',
          visible: true,
          scopedSlots: { customRender: 'description' },
          sorter: true
          //sorter: (a, b) => a.description - b.description,
          //sorter: (a, b) => a.description.localeCompare(b.description),
        },
        {
          title: 'last_modified',
		dataIndex: 'lastModified',
          visible: true,
          scopedSlots: { customRender: 'lastModified' },
          sorter: true
          //sorter: (a, b) => a.lastModified - b.lastModified,
          //sorter: (a, b) => a.lastModified.localeCompare(b.lastModified),
        },
        {
          title: 'created_on',
		dataIndex: 'createdOn',
          visible: true,
          scopedSlots: { customRender: 'createdOn' },
          sorter: true
          //sorter: (a, b) => a.createdOn - b.createdOn,
          //sorter: (a, b) => a.createdOn.localeCompare(b.createdOn),
        },
        {
          title: 'created_by',
		dataIndex: 'createdBy',
          visible: true,
          scopedSlots: { customRender: 'createdBy' },
          sorter: true
          //sorter: (a, b) => a.createdBy - b.createdBy,
          //sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
        },
        {
          title: 'created_by_id',
		dataIndex: 'createdById',
          visible: false,
          scopedSlots: { customRender: 'createdById' },
          sorter: true
          //sorter: (a, b) => a.createdById - b.createdById,
          //sorter: (a, b) => a.createdById.localeCompare(b.createdById),
        },
        {
          title: 'sensitivity_level',
		dataIndex: 'sensitivityLevel',
          visible: true,
          scopedSlots: { customRender: 'sensitivityLevel' },
          sorter: true
          //sorter: (a, b) => a.sensitivityLevel - b.sensitivityLevel,
          //sorter: (a, b) => a.sensitivityLevel.localeCompare(b.sensitivityLevel),
        },
        {
          title: 'image_file',
		dataIndex: 'imageFile',
          visible: true,
          scopedSlots: { customRender: 'imageFile' },
          sorter: true
          //sorter: (a, b) => a.imageFile - b.imageFile,
          //sorter: (a, b) => a.imageFile.localeCompare(b.imageFile),
        },
        {
          title: 'Owner ID',
		dataIndex: 'ownerId',
          visible: true,
          scopedSlots: { customRender: 'ownerId' },
          sorter: true
          //sorter: (a, b) => a.ownerId - b.ownerId,
          //sorter: (a, b) => a.ownerId.localeCompare(b.ownerId),
        },
      ],
      pagination: {
        current: 1,
        pageSize: 50,
        total: 0,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total) => `Total ${total} helmets`,
      },

      helmets: [],
      helmetToAdd: {},

      helmetsTable: {
        columns: [...helmetsColumns],
        data: [],
      },

      // New properties for sorting and searching
      sortBy: 'helmetId',           // Column to sort by
      sortOrder: 'asc',     // Sort order (asc or desc)
      searchQuery: '',      // Search query
      searchLoading: false, // Initialize searchLoading property


    };
  },

  watch: {
    selectedHelmetStatus: {
      handler: "handleSelectedHelmetStatusChanged", // Call the fetchData method when selectedHelmetStatus changes
      immediate: true, // Trigger immediately when the component is mounted
    },
    searchQuery: {
      handler: "handleSearchQueryChanged", // Call the fetchData method when searchQuery changes
      immediate: true, // Trigger immediately when the component is mounted
    },
  },

  methods: {

    async renderHelmetsTable() {
      this.searchLoading = true; // Set searchLoading to true while fetching data
      this.searchLoading = false;

      this.pagination.total = this.totalElements;
      this.pagination.current = this.page;

      let helmetsTableData = [];
      for (let i = 0; i < this.helmets.length; i++) {
        helmetsTableData.push({
          id: i,
          helmetId: this.helmets[i].helmetId,
          year: this.helmets[i].year,
          date: this.helmets[i].date,
          competitionId: this.helmets[i].competitionId,
          helmetId: this.helmets[i].helmetId,
        });

      }
      this.searchLoading = false;
    },

    setSelectedHelmetStatus(selectedHelmetStatus) {
    	this.selectedHelmetStatus = selectedHelmetStatus;
    },

    async onTableChange(pagination, filters, sorter) {
      if (sorter && sorter.field && sorter.order) {
        this.sortBy = sorter.field;
        if (sorter.order == "ascend") {
            this.sortOrder = "asc";
        } else {
            this.sortOrder = "desc";
        }
      }
      if (pagination) {
        this.pagination.current = pagination.current;
        this.pagination.pageSize = pagination.pageSize;
      }

	  this.$emit('get-all-Helmets',this.sortBy,this.sortOrder,this.pagination.current-1,this.pagination.pageSize);
      this.handleTableChanged()
    },
	
	initializeColumns() {
        this.columns = this.columns.filter(item => item.visible);
    },

    routingToHelmetDetail(id) {
      this.$router.push({ name: 'HelmetDetail', params: { helmetId: id.toString() }})
    },
    routingToUserDetail(id) {
      this.$router.push({ name: 'UserDetail', params: { userId: id.toString() }})
    },
    routingToRoleDetail(id) {
      this.$router.push({ name: 'RoleDetail', params: { roleId: id.toString() }})
    },
    routingToUserRoleDetail(id) {
      this.$router.push({ name: 'UserRoleDetail', params: { userRoleId: id.toString() }})
    },
    
    handleSelectedHelmetStatusChanged() {
    	console.log("handleSelectedHelmetStatusChanged called")
    	this.$root.$emit('selectedHelmetStatusChanged', this.selectedHelmetStatus);
    },

    handleSearchQueryChanged() {
    	console.log("handleSearchQueryChanged CALLED FROM @search")
    	this.$root.$emit('searchQueryForHelmetsChanged', this.searchQuery);
		//this.renderHelmetsTable();
    },

    onSearch(value) {
      console.log(value);
      this.searchQuery = value; // Update searchQuery when the user types
    },

    toggleView() {
      this.isTableView = !this.isTableView;
    },
	
	async handleAddSubmitted() {
      this.modalHelmets = false;

      const currentDate = new Date().getTime();
      this.helmetToAdd.created = currentDate;

      const jsonData = JSON.stringify(this.helmetToAdd);
      console.log(jsonData);
      
      const res = await HelmetService.addHelmet(jsonData);

      if (res.status === 200) {
        this.$notify({
          component: NotificationTemplate,
          icon: "tim-icons icon-bell-55",
          type: "success",
          timeout: 3000,
        });
      }
	},
	
	handleTableChanged() {
	},
	
	formatTime(dateString) {
      if(dateString !== null){
        const date = new Date(dateString);
      return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} EST`;
      }
      // Format the date here as needed, for example:
    },  
    
 formatDate(dateString) {
    if (dateString !== null) {
	    const date = new Date(dateString);
	    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	    const day = String(date.getDate()).padStart(2, '0');
	    const monthAbbreviation = months[date.getMonth()];
	    const year = date.getFullYear();
	    return `${day} ${monthAbbreviation} ${year}`;
  	}
  	// Handle the case when dateString is null or invalid
  	return '';
    
   },

  },
  mounted() {
  	this.initializeColumns();
    this.renderHelmetsTable();
  }
};
</script>

<style>
.modal-dialog {
  margin-top: -300px;
}
.ant-table-row  {
	text-align: center;
}
.ant-table-thead th span {
	text-align: center;
	width: 100%
}
.ant-table-fixed td span {
    text-align: center;
}
.header-search {
  width: 300px !important;
  margin-left: auto !important;
}

.nav-text {
 	margin: 5px;
 	padding: 5px;
	width: 180px;
	outline: 2px dashed blue;
	gap: 5px;
	font-style: italic;
}

.filter-chip-container {
	margin-top:8px;  	
}

.current-view-text {
	color: #444;
}

.filter-chip { 
	background-color: white;
     background-color: white; /* Initial background color */
      color: grey; /* Text color */
      border: grey;
      padding: 8px 10px; /* Add padding: 12px top/bottom, 24px left/right */
      border: none; /* Remove default border */
      border-radius: 25px; /* Rounded corners */
      cursor: pointer; /* Pointer cursor on hover */
      transition: background-color 0.3s ease; /* Smooth transition */
      font-size: 16px; /* Font size */
	margin:5px;  	
	border: 2px solid grey; 
}

.filter-chip-selected {
	background-color: #3498db; 
	color: white; /* Text color */
	border: 2px solid #3498db; 
}

.filter-chip-selected:hover {
	background-color: #3498db !important; 
}

.filter-chip:hover {
	background-color: lightGrey;
	border: 2px solid lightGrey; 
}

.header-search .ant-input {
	border-radius: 25px;
}

</style>
