<template>
  <div class="content">
    
    <div>
      <div class="filter-container">
        <select v-model="selectedFilter" class="filter-select">
          <option value="1">All My Helmets</option>
          <option value="2">My Loved Helmets</option>
          <option value="3">My Shelved Helmets</option>
        </select>
      </div>
		<img class="helmet-image" :alt="helmetDetails.helmetNumber" :src="`${helmetDetails.imageFile}`" />
        <div class="button-container">
          <button class="love-it-button" @click="getHelmet(0)">Previous</button>
          <button class="shelve-it-button" @click="getHelmet(1)">Next</button>
        </div>
    </div>

  </div>
</template>

<script>

import HelmetService from "../services/HelmetService";
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/BaseInput";
import NotificationTemplate from "@/pages/Notifications/NotificationTemplate";
import { Card } from "@/components/Card";
import { ASelect, ASelectOption, AButton, Table, Pagination, message } from "ant-design-vue";
import { VueAutosuggest } from "vue-autosuggest";
import { UploadOutlined } from '@ant-design/icons-vue';
import Datepicker from 'vuejs-datepicker';


const tableColumns = [];
const tableData = [];
const fileList = ([]);
let file = ({});
const checked = (false);
let yearValue = ('');

export default {
  props: {
    helmetId: {
      type: String,
      required: true
    }
  },
  components: {
		Modal,
		BaseButton,
		BaseInput,
		VueAutosuggest,
		UploadOutlined,
		Datepicker,
  },
  data() {
    return {
      helmetToAdd: {},
      modal2Helmets: false,
      helmetDetails: null,
      selectedFilter: 1,
      fileList,
      checked,
	  yearValue,

      showGeneratedImageDialog: false, // Add this property to control the visibility of the generated image dialog
      generatedImageUrl: '',
	  imageGenerate: {},
      prompt: '',
      uploadGeneratedImage: {},
      showProgressBar: false,
	  userPermissionDialog:false,
      loadingText: 'Please wait . . .',
      percenatage: 0,
      imgSrc: '',      

    };
  },
  methods: {

    

	
	handleSwitchChange(lifAnswerId, checked) {
	    this.answers.forEach(function(answer) {
	    	if (answer.questionType == '2') {
		    	if (answer.lifAnswerId == lifAnswerId) {
					answer.answerb = checked;
					if ( checked) {
						answer.answer = "Yes";
					} else {
						answer.answer = "No";
					}
				}
	    	}
		});
	},

 	handlePermissionDialogClose() {
      this.userPermissionDialog = false;
    },

   handleChange(info) {
      if (info.file.status === 'error' || info.file.status === 'done') {
        message.success(`upload image now`);
        file = info.file;
      }
    },
    
      async handleUpload() {
      const formData = new FormData();
      formData.append('file', file.originFileObj);
      const res = await HelmetService.uploadImage(formData, this.teamDetails.teamId);
      if (res.status === 200) {
        message.success(
            'Image uploaded successfully',
            10,
        );
      }
    },
    
    async handleAddSubmitted() {
      this.modal2Helmets = false;

      const jsonData = JSON.stringify(this.helmetToAdd);
      console.log(jsonData);
      
      const res = await HelmetService.addHelmet(jsonData);

      if (res.status === 200) {
        this.$notify({
          component: NotificationTemplate,
          icon: "tim-icons icon-bell-55",
          type: "success",
          timeout: 3000,
        });
      }
	},

    async updateHelmet() {

      const jsonData = JSON.stringify(this.helmetDetails);
      const res = await HelmetService.update(jsonData);

      if (res.status === 200) {
//        this.$notify({
//          component: NotificationTemplate,
//          icon: "tim-icons icon-bell-55",
//          type: "success",
//          timeout: 3000,
//        });

//        this.modalHelmets = false;
//        this.getAllHelmets();
      }
    },

	async getHelmet(nextOrPreviousValue) {

		const findHelmetIdData = {
			helmetId : this.helmetId,
			helmetStatus : this.selectedFilter,  
			nextOrPrevious : nextOrPreviousValue
    	};

        const jsonData = JSON.stringify(findHelmetIdData);
        console.log(jsonData);

      	try {
			let response = await HelmetService.findHelmetId(jsonData);
			this.helmetId = response.data;
	
			this.getHelmetDetails();

	      } catch (error) {
	        console.error('Error fetching helmet details:', error);
	      }
	},

    async getHelmetDetails() {
      try {
			let response = await HelmetService.get(this.helmetId);
			this.helmetDetails = response.data;

			let hasAnswers = 0;


 			// fetching image
      		const imagePath = this.helmetDetails.imageFile;
      		const parts = imagePath.split("/");
      		const imageName = parts[parts.length - 1];
      		this.imgSrc = '/images/'+imageName;

			if (hasAnswers) {			
		        this.answers.forEach(function(answer) {
		            if (answer.answer === "Yes") {
		               temp = true;
		            } else {
		               temp = false;
		            }
		        });
			}


	    
      } catch (error) {
        console.error('Error fetching helmet details:', error);
      }
    },


	formatTime(dateString) {
      if(dateString !== null){
        const date = new Date(dateString);
      return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} EST`;
      }
      // Format the date here as needed, for example:
    },  
    
 formatDate(dateString) {
    if (dateString !== null) {
	    const date = new Date(dateString);
	    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	    const day = String(date.getDate()).padStart(2, '0');
	    const monthAbbreviation = months[date.getMonth()];
	    const year = date.getFullYear();
	    return `${day} ${monthAbbreviation} ${year}`;
  	}
  	// Handle the case when dateString is null or invalid
  	return '';
    
   },

  },
  mounted() {
    this.getHelmetDetails();
  },
  computed: {
  },
  
};
</script>





<style>

.detail_view_input input {
	text-align: center;
}

.detail_view_column_1 {
	width: 250px; 
	text-align: center;
}
.detail_view_column_2 {
	min-width: 250px; 
}
.input {
	text-align: center;
}
#autosuggest__input {
  display: inline-block;
}
.autosuggest__results-container {
	position: relative;
}
.autosuggest__results {
	font-weight: 300;
	margin: 0;
	position: absolute;
	z-index: 10000001;
	border: 1px solid #e0e0e0;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	background: white;
}
.autosuggest__results ul {
	list-style: none;
	padding-left: 0;
	margin: 0;
}
.autosuggest__results .autosuggest__results-item {
	cursor: pointer;
	padding: 5px;
}
#autosuggest ul:nth-child(1) > .autosuggest__results_title {
	border-top: none;
}
.autosuggest__results .autosuggest__results_title {
	color: gray;
	font-size: 11px;
	margin-left: 0;
	padding: 15px 13px 5px;
	border-top: 1px solid lightgray;
}
.autosuggest__results .autosuggest__results-item:active,
.autosuggest__results .autosuggest__results-item:hover,
.autosuggest__results .autosuggest__results-item:focus,
.autosuggest__results
.autosuggest__results-item.autosuggest__results-item--highlighted {
	background-color: #F6F6F6;
}

.user-image {
			    	height: 250px;
		}

.inline-item {
  display: inline-block;
  margin-right: 100px; /* Adjust margin as needed */
}

.switch-container {
	width: 215px; 
    text-align: right;
    margin-right: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}


.content {
    margin-top: 70px;
}

.love-it-button {
  background: linear-gradient(to bottom left, #00f2c3, #1890FF);
}

.shelve-it-button {
  background: linear-gradient(to bottom left, #e8e8e8, #8c8c8c, #1890FF);
}





.love-it-button {
  background: linear-gradient(to bottom left, #00f2c3, #1890FF);
  margin-right: 75px;
}

.shelve-it-button {
  background: linear-gradient(to bottom left, #e8e8e8, #8c8c8c, #1890FF);
  margin-left: 75px;
}



.helmet-image {
  margin: 5px;
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 35px;
  object-fit: contain;
}


.button-container {
  justify-content: space-between;
  margin-top: 20px;

}

.filter-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.filter-select {
  padding: 8px 15px;
  border-radius: 0.4285rem;
  border: 2px solid #1890FF;
  font-size: 0.875rem;
  background: white;
  color: #1890FF;
  cursor: pointer;
  transition: all 0.15s ease;
  width: 200px;
}

.filter-select:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

</style>




